/*
 *  ======================================= 
 *  PROJEKT TWORZONY ZA POMOCA
 *  PREPROCESORA SCSS
 *	JESLI CHCESZ EDYTOWAC COKOLWIEK EDYTUJ
 *  PLIKI SASS  SCSS | CMS.SCSS
 *  =======================================  
 */

@import 'modules/_elements';
@import 'partials/_colors';
@import 'partials/_cms';