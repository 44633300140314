@mixin after_border($bg,$width,$bottom,$height){
	bottom:$bottom;
	width:$width;
	background:$bg;
	height:$height;
	position:absolute;
	content:'';
	left:0px;
}
@mixin transition($easing,$time){
	transition:all $easing $time;
	-webkit-transition:all $easing $time;
}
@mixin trans_delay($time){
	transition-delay:$time;
	-webkit-transition-delay:$time;
}
@mixin after_overlay($bg){
	content:'';
	position:absolute;
	width:100%;
	height:100%;
	z-index:1;
	background:$bg;
	top:0px;
	left:0px;
}
@mixin translate($x,$y){
	transform: translate($x,$y);
	-webkit-transform: translate($x,$y);
}

@mixin rotate($deg){
	transform:rotate($deg);
	-webkit-transform:rotate($deg);
}

@mixin skew($deg){
	transform:skew($deg);
	-webkit-transform:skew($deg);
}

@mixin circle_image($w,$h,$position,$center){
	width:$w;
	height:$h;
	border-radius:50%;
	-webkit-border-radius:50%;
	
	overflow:hidden;
	@extend %background-cover;
	position: $position;
   	
   	@if $center == center{
   		@include translate(-50%,-50%);
   	}
   	@else if $center == center-x{
   		@include translate(-50%,0px);
   	}
   	@else if $center == center-y{
   		@include translate(0px,-50%);
   	}
   	
   
}

@mixin border_radius($rad){
	border-radius:$rad;
	-webkit-border-radius:$rad;
}

@mixin block_center(){
	float:left;
	margin-left:50%;
	@include translate(-50%,0px);
	white-space:nowrap;
}

@mixin animation($animate...) {
    $max: length($animate);
    $animations: '';

    @for $i from 1 through $max {
        $animations: #{$animations + nth($animate, $i)};

        @if $i < $max {
            $animations: #{$animations + ", "};
        }
    }
    -webkit-animation: $animations;
    -moz-animation:    $animations;
    -o-animation:      $animations;
    animation:         $animations;
}

@mixin keyframes($animationName) {
    @-webkit-keyframes #{$animationName} {
        @content;
    }
    @-moz-keyframes #{$animationName} {
        @content;
    }
    @-o-keyframes #{$animationName} {
        @content;
    }
    @keyframes #{$animationName} {
        @content;
    }
}


@mixin background-opacity($color, $opacity: 0.3) {
    background: $color; 
    background: rgba($color, $opacity);
}


%block-center{
	float:left;
	margin-left:50%;
	@include translate(-50%,0px);
}
%float-100{
	float:left;
	width:100%;
}
%list-no-style{
	list-style-type:none;
	padding:0px;
	margin:0px;
}
%table-fixed{
	table-layout:fixed;
}
%text-uppercase{
	text-transform:uppercase;
}
%background-cover{
	background-size:cover;
	background-repeat:no-repeat;
	background-position:center center;
}
%button{
	padding:8px 20px;
	font-size:14px;
	@include transition(ease,.3s);
	text-decoration:none !important;
}
%button-radius{
	border-radius:25px;
}
%button-center{
	float:left;
	margin-left:50%;
	@include translate(-50%,0px);
}